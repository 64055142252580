import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Container } from './styles';

const Background = ({ children }) => {
	const node = useRef(null);

	const match = useRouteMatch();

	useEffect(() => {
		function bgAnimate(e) {
			const cur = node.current;

			if (cur) {
				const bgNode = cur;

				const { clientX, clientY } = e;

				bgNode.style.backgroundPositionX = `${
					50 + ((clientX * 100) / window.innerWidth) * 0.01
				}%`;

				bgNode.style.backgroundPositionY = `${
					50 + ((clientY * 100) / window.innerHeight) * 0.01
				}%`;
			}
		}

		window.addEventListener('mousemove', bgAnimate);

		return () => window.removeEventListener('mousemove', bgAnimate);
	}, []);

	return (
		<Container ref={node} noPadding={match.path === '/map'}>
			{children}
		</Container>
	);
};

export default Background;
