import produce from 'immer';
import * as constants from './constants';

const INITIAL_STATE = {
	data: [],
	error: false,
	loading: false,
	done: false,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case constants.SEARCH_REQUEST:
			return produce(state, (draft) => {
				draft.loading = true;
			});
		case constants.SEARCH_SUCCESS:
			return produce(state, (draft) => {
				draft.loading = false;
				draft.error = false;
				draft.done = true;
				draft.data = action.payload.data;
			});
		case constants.SEARCH_FAILURE:
			return produce(state, (draft) => {
				draft.error = true;
				draft.loading = false;
				draft.data = [];
			});
		case constants.CLEAR_ERROR:
			return produce(state, (draft) => {
				draft.error = null;
			});
		case constants.CLEAR_DONE:
			return produce(state, (draft) => {
				draft.done = false;
				draft.data = [];
			});
		default:
			return state;
	}
};

export default reducer;
