import * as constants from './constants';

export const sendEmailResquest = (data) => ({
	type: constants.SEND_EMAIL_REQUEST,
	payload: data,
});

export const sendEmailFailure = () => ({
	type: constants.SEND_EMAIL_FALURE,
	payload: {},
});

export const sendEmailSuccess = () => ({
	type: constants.SEND_EMAIL_SUCCESS,
	payload: {},
});

export const clearSuccess = () => ({
	type: constants.CLEAR_SUCCESS,
	payload: {},
});

export const clearError = () => ({
	type: constants.CLEAR_ERROR,
	payload: {},
});
