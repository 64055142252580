import { all, takeLatest, put } from 'redux-saga/effects';

import pagesContent from '~/config/pages';

import { SEARCH_REQUEST } from './constants';

import { searchSuccess, searchFailure, clearDone } from './actions';

export function* searchRequest({ payload: { value } }) {
	try {
		if (!value) {
			yield put(clearDone());
		} else {
			yield put(
				searchSuccess(
					pagesContent.filter((e) =>
						e.label
							.toUpperCase()
							.trim()
							.includes(value.toUpperCase().trim())
					)
				)
			);
		}
	} catch (error) {
		yield put(searchFailure());
	}
}

export default all([takeLatest(SEARCH_REQUEST, searchRequest)]);
