import * as constants from './constants';

export const loadRequest = (tag) => ({
	type: constants.LOAD_REQUEST,
	payload: { tag },
});

export const loadSuccess = (data) => ({
	type: constants.LOAD_SUCCESS,
	payload: { data },
});

export const loadFailure = () => ({
	type: constants.LOAD_FAILURE,
	payload: {},
});

export const changeType = (type) => ({
	type: constants.CHANGE_TYPE,
	payload: { type },
});
