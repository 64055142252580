import styled from 'styled-components';
import { NavigationControl } from 'react-map-gl';

export const Container = styled.div`
	flex: 1;
	overflow: auto;
`;

export const Navigation = styled(NavigationControl)`
	border-radius: 0 !important;
	width: max-content;

	button {
		cursor: pointer !important;
		width: 29px !important;
		height: 29px !important;
		background-color: #ffff !important;
		border: none !important;

		&:active {
			box-shadow: none !important;
			transform: none !important;
		}

		&:focus {
			box-shadow: none !important;
			transform: none !important;
		}

		& + button {
			border-top: 1px solid #d6d6d6 !important;
		}

		span {
			width: 29px !important;
			height: 29px !important;
		}

		svg {
			color: #002f66 !important;
		}
	}
`;

export const RightOp = styled.div`
	padding: 50px 10px 50px 0;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const TopOp = styled.div`
	padding: 10px 50px 0px 50px;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
`;

export const Background = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: #fff;
`;
