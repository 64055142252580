import styled from 'styled-components';
import { DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

export const Toggle = styled(DropdownToggle)`
	width: 100%;
	min-height: calc(1.5em + 1.5rem);
	padding: calc(0.7rem - 6px) calc(1rem - 6px);
	font-size: 0.875rem;
	font-weight: 400;
	color: rgb(51, 51, 51);
	background-color: #f7f7f9;
	border: 0 solid #ced4da;
	border-radius: 0;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;

	&:focus {
		background-color: #f7f7f9;
		border-color: #5a5a5a;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(26, 26, 26, 0.25);
	}
`;

export const ItensContent = styled.div`
	flex: 1;
	display: flex;
	flex-wrap: wrap;
`;

export const ControlContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;

	span {
		margin: 0 5px;
		opacity: 0.3;
	}

	svg {
		opacity: 0.4;
		transition: opacity 100ms ease-in-out;

		&:hover {
			opacity: 1;
			transition: opacity 100ms ease-in-out;
		}
	}
`;

export const ItemSelected = styled.div`
	display: flex;
	background-color: rgb(230, 230, 230);
	margin: 2px;
	font-size: 0.8rem;
`;

export const ItemSelectedFrst = styled.span`
	padding: 5px;
	margin: 0 !important;
	text-align: left;
`;

export const ItemSelectedScnd = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
	padding: 5px;
	background-color: transparent;
	transition: color 200ms ease-in-out, background-color 200ms ease-in-out;

	&:hover {
		background-color: #ffcdd2;
		color: #c62828;
		transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
	}
`;

export const OptionsMenu = styled(DropdownMenu)`
	width: 100%;
`;

export const MenuItem = styled(DropdownItem)`
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 11pt;

	&:hover {
		background-color: #cecece !important;
	}
`;

export const SearchContainer = styled.div`
	padding: 4px 24px;
	margin-bottom: 5px;
`;

export const ItemsContainer = styled.div`
	width: 100%;
	max-height: 300px;
	overflow-y: auto;
`;

export const Placeholder = styled.p`
	margin: 0 !important;
	opacity: 0.5;
`;
