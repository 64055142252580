import createSagMiddleware from 'redux-saga';
import { createStore, compose, applyMiddleware } from 'redux';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagMiddleware();

const middlewares = [sagaMiddleware];

const enhancer =
	process.env.NODE_ENV === 'development'
		? compose(applyMiddleware(...middlewares))
		: applyMiddleware(...middlewares);

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
