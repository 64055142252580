// import { call } from '@redux-saga/core/effects';
import axios from 'axios';
import { host as baseURL,login,geowise_token } from '../config/api';

const api = axios.create({ baseURL });


export const signIn = async () => {
	api.defaults.headers.Accept = 'application/json';
	api.defaults.headers['Content-Type'] = 'application/json';
	api.defaults.headers['X-User-Login'] = login;
	api.defaults.headers['X-User-Token'] = geowise_token;

	// Codigo abaixo comentado comentado pois estava enviando o password como string

	// const savedToken = sessionStorage.getItem('@geoportal/TOKEN');
	// if (!savedToken) {
	// 	try {
	// 		const { data } = await api.post('api/sign-in', {
	// 			login,
	// 			password,
	// 		});

	// 		sessionStorage.setItem(
	// 			'@geoportal/TOKEN',
	// 			data.authentication_token
	// 		);

	// 		api.defaults.headers['X-User-Token'] = data.authentication_token;
	// 	} catch (error) {
	// 		throw new Error('Erro ao tentar autenticar');
	// 	}
	// } else {
	// 	api.defaults.headers['X-User-Token'] = savedToken;
	// }
}

export default api;
