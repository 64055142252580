import * as constants from './constants';

export const searchResquest = (value) => ({
	type: constants.SEARCH_REQUEST,
	payload: { value },
});

export const searchSuccess = (data) => ({
	type: constants.SEARCH_SUCCESS,
	payload: { data },
});

export const searchFailure = () => ({
	type: constants.SEARCH_FAILURE,
	payload: {},
});

export const clearFailure = () => ({
	type: constants.CLEAR_ERROR,
	payload: {},
});

export const clearDone = () => ({
	type: constants.CLEAR_DONE,
	payload: {},
});
