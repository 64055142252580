import styled from 'styled-components';
import { ButtonGroup as ButtonGroupBT } from 'reactstrap';

const DISPLAY_WIDTH = '630px';

export const Content = styled.div`
	flex: 1;
	transition: width ease-in-out 200ms;
	display: flex;

	@media (max-width: ${DISPLAY_WIDTH}) {
		width: 100%;
		flex-direction: column;
		transition: width ease-in-out 200ms;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		width: 80%;
		transition: width ease-in-out 200ms;
	}
`;

export const Section = styled.section`
	width: 100%;
	height: max-content;
	color: #002f66;
	background-color: #fff;
`;

export const ButtonGroup = styled(ButtonGroupBT)`
	@media (max-width: 400px) {
		display: flex !important;
		flex-direction: column;
	}
`;
