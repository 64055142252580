import React from 'react';

import { Container, Icon } from './styles';

const Waiter = ({ style }) => {
	return (
		<Container style={style}>
			<Icon />
		</Container>
	);
};

export default Waiter;
