import produce from 'immer';
import * as constants from './constants';

const INITIAL_STATE = {
	error: false,
	loading: false,
	success: false,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case constants.SEND_EMAIL_REQUEST:
			return produce(state, (draft) => {
				draft.loading = true;
			});
		case constants.SEND_EMAIL_SUCCESS:
			return produce(state, (draft) => {
				draft.success = true;
				draft.loading = false;
				draft.error = false;
			});
		case constants.SEND_EMAIL_FALURE:
			return produce(state, (draft) => {
				draft.error = true;
				draft.loading = false;
			});
		case constants.CLEAR_SUCCESS:
			return produce(state, (draft) => {
				draft.success = false;
			});
		case constants.CLEAR_ERROR:
			return produce(state, (draft) => {
				draft.error = false;
			});
		default:
			return state;
	}
};

export default reducer;
