import styled from 'styled-components';

export const Container = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	overflow-y: auto;
`;

export const Modal = styled.div`
	width: 1100px;
	height: 95vh;
	background: #fff;
	box-shadow: 0px 7px 20px -6px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	overflow-y: visible;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: center;
	
	.report-header-container {
		text-align: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
	}

	.report-header-actions-container {
		right: 0;
		top: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: center;
	}

	hr {
		margin: 0.5em 0;
	}

	iframe {
		width: 100%;
		height: 100%;
		flex-grow:1;
		background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100%' width='100%'><text x='50%' y='50%' text-anchor='middle' fill='red' font-size='20'> Opção de Download será exibida em seu navegador. </text></svg>");
		background-repeat: no-repeat;
	}
`;
