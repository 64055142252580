import produce from 'immer';
import * as constants from './constants';

const INITIAL_STATE = {
	data: null,
	error: false,
	loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case constants.LOAD_REQUEST:
			return produce(state, (draft) => {
				draft.loading = true;
			});
		case constants.LOAD_SUCCESS:
			return produce(state, (draft) => {
				draft.loading = false;
				draft.error = false;
				draft.data = action.payload.data;
			});
		case constants.LOAD_FAILURE:
			return produce(state, (draft) => {
				draft.error = true;
				draft.loading = false;
				draft.data = [];
			});
		case constants.CHANGE_TYPE:
			return produce(state, (draft) => {
				draft.type = action.payload.type;
			});
		default:
			return state;
	}
};

export default reducer;
