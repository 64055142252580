import React from 'react';
import { Route as OriginalRoute } from 'react-router-dom';

import Navbar from '~/Components/Navbar';
import Background from '~/Components/Background';

const Route = ({ component: Component, ...rest }) => (
	<OriginalRoute
		{...rest}
		component={() => (
			<>
				<Navbar />
				<Background>
					<Component />
				</Background>
			</>
		)}
	/>
);

export default Route;
