import { all, takeLatest, put, call } from 'redux-saga/effects';

import api from '~/services/api';
import getToken from '../auth';

import { SEND_EMAIL_REQUEST } from './constants';

import { sendEmailFailure, sendEmailSuccess } from './actions';

export function* sendEmailRequest({ payload }) {
	try {
		yield call(getToken);

		const {
			data: { success },
		} = yield call(api.post, `geowise/report_doubts/send_email`, payload);

		if (success) {
			yield put(sendEmailSuccess());
		} else {
			yield put(sendEmailFailure());
		}
	} catch (error) {
		yield put(sendEmailFailure());
	}
}

export default all([takeLatest(SEND_EMAIL_REQUEST, sendEmailRequest)]);
