import styled, { css } from 'styled-components';

const DISPLAY_WIDTH = '630px';

export const Content = styled.div`
	flex: 1;
	transition: width ease-in-out 200ms;
	display: flex;

	@media (max-width: ${DISPLAY_WIDTH}) {
		width: 100%;
		flex-direction: column;
		transition: width ease-in-out 200ms;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		width: 80%;
		transition: width ease-in-out 200ms;
	}
`;

export const Aside = styled.aside`
	background-color: #eee;
	height: max-content;
	display: flex;
	flex-direction: column;

	h3 {
		font-weight: 600;
		font-size: 1rem;
		white-space: nowrap;
		padding: 20px 20px 20px 20px;
		cursor: default;
		opacity: 0.5;
	}

	@media (max-width: ${DISPLAY_WIDTH}) {
		margin-bottom: 20px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		margin-right: 3px;
	}
`;

export const Section = styled.section`
	width: 100%;
	height: max-content;
	color: #002f66;
	background-color: #fff;
`;

export const Item = styled.div`
	text-decoration: none;
	color: #002f66;
	text-align: center;
	padding: 10px 20px;
	border-bottom: 2px solid transparent;
	font-size: 0.9rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	transition: border-bottom 150ms ease-in-out, transform 150ms ease-in-out,
		background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

	@media (min-width: ${DISPLAY_WIDTH}) {
		max-width: 200px;
	}

	${(p) =>
		p.selected &&
		css`
			background-color: #fdae45;
			box-shadow: 0px 8px 20px -6px rgba(0, 0, 0, 0.5);
			transform: scale(1.05);
			transition: border-bottom 150ms ease-in-out,
				transform 150ms ease-in-out, background-color 150ms ease-in-out,
				box-shadow 150ms ease-in-out;
		`}

	&:hover {
		background-color: #fdae45;
		box-shadow: 0px 8px 20px -6px rgba(0, 0, 0, 0.5);
		transform: scale(1.05);
		transition: border-bottom 150ms ease-in-out, transform 150ms ease-in-out,
			background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
	}
`;

export const EmptyForm = styled.div`
	flex: 1;
	display: flex;
	cursor: default;

	@media (max-width: ${DISPLAY_WIDTH}) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		svg {
			transform: rotate(90deg);
			margin-bottom: 10px;
		}
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		transform: rotate(0deg);
		justify-content: flex-start;
		align-items: center;

		svg {
			margin-right: 10px;
		}
	}
`;

export const Docs = styled.div`
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	scroll-margin-left: 30px;
`;

export const BtnCont = styled.div`
	display: flex;
	margin-top: 10px;
	justify-content: flex-end;

	button {
		margin-top: 10px;
		align-self: flex-end;
		margin-left: 10px;
	}
`;

export const ItemForm = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;

	input {
		margin-top: 2px;
	}
`;
